/* global pell */

var richTextEditorActions = [
  {
    name: 'bold',
    icon: '<div class="form2__rte__icon form2__rte__icon--bold"></div>'
  },
  {
    name: 'italic',
    icon: '<div class="form2__rte__icon form2__rte__icon--italic"></div>'
  },
  {
    name: 'underline',
    icon: '<div class="form2__rte__icon form2__rte__icon--underline"></div>'
  },
  {
    name: 'ulist',
    icon: '<div class="form2__rte__icon form2__rte__icon--list"></div>'
  },
  {
    name: 'link',
    icon: '<div class="form2__rte__icon form2__rte__icon--link"></div>',
    result: function() {
      handleLinkPromt();
    }
  },
  {
    name: 'indent',
    icon: '<div class="form2__rte__icon form2__rte__icon--indent"></div>',
    result: function() {
      pell.exec('indent');
    }
  },
  {
    name: 'outdent',
    icon: '<div class="form2__rte__icon form2__rte__icon--outdent"></div>',
    result: function() {
      pell.exec('outdent');
    }
  }
];

if (window.IS_ADMIN) {
  richTextEditorActions = richTextEditorActions.concat([
    {
      name: 'youtube',
      icon: '<div class="form2__rte__icon form2__rte__icon--youtube"></div>',
      result: function() {
        var youtubeEmbed = createYoutubeEmbed(
          window.prompt('Youtube-videon linkki, ID tai upotuskoodi')
        );
        pell.exec('insertHTML', youtubeEmbed);
      }
    }
  ]);
}

forEachElement(document.querySelectorAll('.form2__rte'), function(x) {
  // Ignore link clicks within the editor
  x.addEventListener('click', function(e) {
    if (e.target.nodeName.toLowerCase() === 'a') {
      e.preventDefault();
      var link = e.target.getAttribute('href');
      var text = e.target.textContent;
      handleLinkPromt(link, text, e);
    }
  });

  // Initialize pell on an HTMLElement
  var editor = pell.init({
    element: x,

    onChange: function(content) {
      document.querySelector('#' + x.dataset.targetEl).innerHTML = content;
    },

    defaultParagraphSeparator: 'div',

    styleWithCSS: false,

    actions: richTextEditorActions,

    classes: {
      actionbar: 'form2__rte__actions',
      button: 'form2__rte__action',
      content: 'form2__rte__content',
      selected: 'form2__rte__action--selected'
    }
  });

  // Parse pasted content
  detectPaste(editor.content, function(content) {
    content.html
      ? insertHTMLAtCursor(content.html)
      : insertTextAtCursor(content);
    document.querySelector('#' + x.dataset.targetEl).innerHTML =
      editor.content.innerHTML;
  });

  // Update rich text editor content with the contents within the form field
  var targetField = document.querySelector('#' + x.dataset.targetEl);
  if (targetField.innerHTML)
    editor.content.innerHTML = parseHTML(targetField.innerHTML);
});

function handleLinkPromt(default_link, default_text, e) {
  // simple match if hyperlink contains number -> number as tel:
  // if contains email -> mailto:
  // otherwise keep original action

  if (default_link && default_link.startsWith('tel:')) {
    default_link = default_link.substring(4);
  }

  if (default_link && default_link.startsWith('mailto:')) {
    default_link = default_link.substring(7);
  }

  var link = '';
  if (default_link) {
    link = window.prompt('Linkin osoite', default_link);
  } else {
    link = window.prompt('Linkin osoite');
  }

  // User cancelled action
  if (link === null) {
    return;
  }

  // User entered empty link
  if (link === '') {
    if (e) {
      var text = e.target.textContent;
      e.target.parentNode.removeChild(e.target);
      pell.exec('insertHTML', text);
    } else {
      pell.exec('unlink');
    }
    return;
  }

  var linkValue = link;
  var link_match = link.startsWith('http');
  var number_match = link.match(
    /(?:\+|00)?(\d{2,3}[-.\s]??\d{1,3}?[-.\s]??\d{3,4}[-.\s]??\d{2,4}?[-.\s]??\d{2,4}?)/g
  );
  var email_match = link.match(
    /[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+/g
  );

  if (!link_match) {
    if (number_match && number_match.length > 0) {
      linkValue = number_match[0].replace(/\s/g, '');

      if (!linkValue.startsWith('tel:')) {
        linkValue = 'tel:' + linkValue;
      }
    } else if (email_match && email_match.length > 0) {
      linkValue = email_match[0].replace(/\s/g, '');

      if (!linkValue.startsWith('mailto:')) {
        linkValue = 'mailto:' + linkValue;
      }
    } else {
      if (!link.startsWith('http')) {
        linkValue = 'https://' + link;
      }
    }
  }

  if (default_text && e) {
    e.target.href = linkValue;
  } else {
    pell.exec('createLink', linkValue);
  }
}

function forEachElement(nodeList, fn) {
  if (nodeList) {
    for (var i = 0; i < nodeList.length; i++) {
      var element = nodeList[i];
      fn(element);
    }
  }
}

function parseTxt(txt) {
  return lineBreaksToBr(txt);
}

function lineBreaksToBr(txt) {
  return txt.replace(/(?:\r\n|\r|\n)/g, '<br>');
}

function parseHTML(html) {
  var parsed = '';
  var tmpEl = document.createElement('div');
  tmpEl.innerHTML = html.trim();

  parseElement(tmpEl);

  function parseElement(el) {
    if (isBadElement(el)) return;

    var wrap = getWrappingElement(el);

    parsed += wrap.start;

    if (!el.childNodes.length) {
      parsed += el.textContent;
    } else {
      forEachElement(el.childNodes, function(x) {
        parseElement(x);
      });
    }

    parsed += wrap.end;
  }

  function isComment(txt) {
    return txt.indexOf('<!--') >= 0 && txt.indexOf('-->') >= 0;
  }

  function isStyle(el) {
    return el.nodeName.toLowerCase() === 'style';
  }

  function isWordFragment(txt) {
    return txt.indexOf('StartFragment') >= 0 || txt.indexOf('EndFragment') >= 0;
  }

  function isBadElement(el) {
    return (
      isComment(el.textContent) || isStyle(el) || isWordFragment(el.textContent)
    );
  }

  return parsed;
}

function getWrappingElement(el) {
  var node = el.nodeName.toLowerCase();
  var parentNode = el.parentNode ? el.parentNode.nodeName.toLowerCase() : null;

  if (
    (node === 'b' && !el.id.startsWith('docs-internal-guid')) || // Ignore random gdrive default bold wrapper
    (el.style && (el.style.fontWeight === 'bold' || el.style.fontWeight > 400))
  )
    return { start: '<b>', end: '</b>' };

  if (
    (node === 'i' && !el.id.startsWith('docs-internal-guid')) || // Ignore random gdrive default bold wrapper
    (el.style && el.style.fontStyle === 'italic')
  )
    return { start: '<i>', end: '</i>' };

  if (
    (node === 'u' && !el.id.startsWith('docs-internal-guid')) || // Ignore random gdrive default bold wrapper
    (el.style && el.style.textDecoration === 'underline')
  )
    return { start: '<u>', end: '</u>' };

  if (node === 'ul' || node === 'ol') {
    if (parentNode === 'li') return { start: '<ul>', end: '</ul>' }; // Sublist -> no extra line breaks
    return { start: '<ul>', end: '</ul>' };
  }

  if (node === 'blockquote')
    return { start: '<blockquote>', end: '</blockquote>' };
  if (node === 'li') return { start: '<li>', end: '</li>' };
  if (node === 'a') return { start: '<a href="' + el.href + '">', end: '</a>' };

  if (parentNode !== 'li' && node === 'p') {
    return { start: '', end: '<br><br>' };
  }

  // Other block level elements -> add line breaks
  if (
    parentNode !== 'li' &&
    (node === 'h1' ||
      node === 'h2' ||
      node === 'h3' ||
      node === 'h4' ||
      node === 'h5' ||
      node === 'h6')
  )
    return { start: '', end: '<br><br>' };

  if (node === 'br') return { start: '', end: '<br>' };

  return { start: '', end: '' };
}

function detectPaste(editable, callback) {
  var isIE = !!document.documentMode;
  var prePasteContent;

  editable.addEventListener('paste', function(e) {
    var clipboard = e.clipboardData;

    // Modern browsers with clipboard API - everything other than
    // _very_ old android web views and UC browser which doesn't
    // support the paste event at all.
    // Need to also ignore IE as only has text and URL support
    if (clipboard && !isIE) {
      var data = {};
      var types = clipboard.types;

      e.preventDefault();

      for (var i = 0; i < types.length; i++) {
        data[types[i]] = clipboard.getData(types[i]);
      }

      var result =
        data['text/html'] && !isXmlns(data['text/html'])
          ? { html: parseHTML(data['text/html']) }
          : { html: parseTxt(data['text/plain']) };

      result.html = formatPastedContent(result.html);

      callback(result);

      // If fragment exists then we're already waiting for a
      // previous paste so let that handler for that handle this one
    } else if (!prePasteContent) {
      // Save the scroll position so can restore it with contents
      var scrollTop = editable.scrollTop;

      prePasteContent = document.createDocumentFragment();
      while (editable.firstChild) {
        prePasteContent.appendChild(editable.firstChild);
      }

      setTimeout(function() {
        var html = editable.innerHTML;

        editable.innerHTML = '';
        editable.appendChild(prePasteContent);
        editable.scrollTop = scrollTop;
        prePasteContent = false;

        // var result = isXmlns(html) ? parseHTML(html) : parseTxt(html);
        var result = { html: parseHTML(html) };

        callback(result);
      }, 0);
    }
  });

  function isXmlns(txt) {
    return txt.indexOf('xmlns') >= 0;
  }
}

function insertTextAtCursor(text) {
  var sel, range;
  if (window.getSelection) {
    sel = window.getSelection();
    if (sel.getRangeAt && sel.rangeCount) {
      range = sel.getRangeAt(0);
      range.deleteContents();
      range.insertNode(document.createTextNode(text));
    }
  } else if (document.selection && document.selection.createRange) {
    document.selection.createRange().text = text;
  }
}

function insertHTMLAtCursor(html) {
  var sel, range;

  var tmpEl = document.createElement('div');
  tmpEl.innerHTML = html;

  if (window.getSelection) {
    sel = window.getSelection();
    if (sel.getRangeAt && sel.rangeCount) {
      range = sel.getRangeAt(0);
      range.deleteContents();
      range.insertNode(tmpEl);
    }
  } else if (document.selection && document.selection.createRange) {
    document.selection.createRange().text = html;
  }
}

function formatPastedContent(content) {
  content = content.trim();

  // Handle all <br> <br /> <br/ > etc
  // Remove spaces, replace groups of 3 <br> tags or more for better styling
  content = content.replace(/(<br\s*\/*\s*>\s*){3,}/g, '<br><br>');

  // Remove extra spaces after ul, sometimes happens when copying from word
  content = content.replace(/(<\/ul>\s*(<br\s*\/*\s*>\s*){2,})/g, '</ul><br>');

  return content;
}

function createYoutubeEmbed(input) {
  if (input.indexOf('iframe') >= 0) return input;
  var youtubeId = getYoutubeID(input);
  return (
    '<iframe width="560" height="315" src="https://www.youtube.com/embed/' +
    youtubeId +
    '" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
  );
}

function getYoutubeIDFromUrl(url) {
  var urlParams = new URLSearchParams(url.split('?')[1]);
  return urlParams.get('v');
}

function getYoutubeIDFromShort(url) {
  var parts = url.split('/');
  return parts[parts.length - 1];
}

function getYoutubeID(from) {
  return from.indexOf('youtube.com') >= 0
    ? getYoutubeIDFromUrl(from)
    : from.indexOf('youtu.be') >= 0
    ? getYoutubeIDFromShort(from)
    : from;
}